
// carousel products
export const carouselProducts = [{
  id: "ac304aa4-ca31-4590-a698-e0985bd2d038",
  slug: "introducing-new-winter-collection",
  shop: {
    id: "319e3a86-0f1a-4481-ade8-a27b49150734",
    slug: "scroll-through",
    user: {
      id: "ab6d9f01-6465-4d0c-9544-edc061246713",
      email: "Beau96@gmail.com",
      phone: "(581) 792-8474 x800",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/961.jpg",
      password: "_Mu_bFfGatgJZu5",
      dateOfBirth: "1973-09-06T18:58:58.895Z",
      verified: true,
      name: {
        firstName: "Mina",
        lastName: "Sipes"
      }
    },
    email: "Evangeline65@hotmail.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Introducing New Winter Collection",
  brand: null,
  price: 87,
  size: null,
  colors: [],
  discount: 30,
  thumbnail: "/assets/images/products/chair-with-clothes.png",
  images: ["/assets/images/products/chair-with-clothes.png", "/assets/images/products/chair-with-clothes.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4
}, {
  id: "d0dd59d8-0cb0-4f09-92a8-e3f5bd9498b2",
  slug: "introducing-new-headphone-collection",
  shop: {
    id: "8d830aa1-c4e3-4071-b8fd-47302495fed7",
    slug: "constant-shoppers",
    user: {
      id: "5868b128-877d-464b-8ddc-8f6aa587eab8",
      email: "Kayden.Kling@yahoo.com",
      phone: "(253) 970-1322 x63522",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/65.jpg",
      password: "KEjhI36IAhDyS2z",
      dateOfBirth: "1969-08-10T16:37:56.266Z",
      verified: true,
      name: {
        firstName: "Hilma",
        lastName: "Bayer"
      }
    },
    email: "Thalia.Schmeler62@gmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Introducing New Headphone Collection",
  brand: null,
  price: 39,
  size: null,
  colors: [],
  discount: 40,
  thumbnail: "/assets/images/products/headphone.png",
  images: ["/assets/images/products/headphone.png", "/assets/images/products/headphone.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "160be9b1-67d0-43c1-82b4-3a1c247ec4a5",
  slug: "introducing-gaming-gear-collection",
  shop: {
    id: "b62876ce-3749-4d7e-8971-c8c9ab4bcea0",
    slug: "anytime-buys",
    user: {
      id: "35ac0881-8742-43a5-8ea2-cef3906677e1",
      email: "Ashleigh.Ondricka81@yahoo.com",
      phone: "933-304-5807 x375",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/103.jpg",
      password: "GcDWvU_Xds3ha83",
      dateOfBirth: "1942-03-08T18:08:28.103Z",
      verified: true,
      name: {
        firstName: "Antone",
        lastName: "Gerhold"
      }
    },
    email: "Howell61@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Introducing Gaming Gear Collection",
  brand: null,
  price: 99,
  size: null,
  colors: [],
  discount: 60,
  thumbnail: "/assets/images/products/gaming-gear.png",
  images: ["/assets/images/products/gaming-gear.png", "/assets/images/products/gaming-gear.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4
}]; 
// banners

export const bannerData = [{
  buttonText: "SHOP NOW",
  thumbnail: "/assets/images/products/table.png",
  title: "Big Saves on Home Decoration. Save Upto 80%",
  description: "Easy payment policy, 12 months 0% in interest."
}, {
  buttonText: "SHOP NOW",
  thumbnail: "/assets/images/products/clothes.png",
  title: "Exclusive Men’s Clothes. Save Upto 50%",
  description: "Comfortable, stylish, elegant and long lasting."
}]; 
// categories

export const categories = [{
  id: "6afcb0c1-6a80-4391-a8cd-cbdff6924609",
  name: "CAMERA",
  icon: null,
  image: "/assets/images/products/camera.png",
  slug: "camera",
  parent: [],
  description: null,
  for: {
    demo: "gadget",
    type: "featured-categories"
  }
}, {
  id: "69dd00f4-a1ba-4691-96ef-dd99118410c3",
  name: "GAMING",
  icon: null,
  image: "/assets/images/products/game-box.png",
  slug: "gaming",
  parent: [],
  description: null,
  for: {
    demo: "gadget",
    type: "featured-categories"
  }
}, {
  id: "80356673-388e-47dd-96f3-c78197a38e64",
  name: "WATCH",
  icon: null,
  image: "/assets/images/products/watch.png",
  slug: "watch",
  parent: [],
  description: null,
  for: {
    demo: "gadget",
    type: "featured-categories"
  }
}, {
  id: "6d8f247d-501b-4e83-97d8-744158c12a1f",
  name: "DRONE",
  icon: null,
  image: "/assets/images/products/drone.png",
  slug: "drone",
  parent: [],
  description: null,
  for: {
    demo: "gadget",
    type: "featured-categories"
  }
}, {
  id: "71d52e2c-7707-40a7-8149-c7373be25dcd",
  name: "PHONE",
  icon: null,
  image: "/assets/images/products/mobile-2.png",
  slug: "phone",
  parent: [],
  description: null,
  for: {
    demo: "gadget",
    type: "featured-categories"
  }
}]; 
// articles

export const articles = [{
  id: "c8f24a5d-4337-4a35-b8a5-2f05dc3201ff",
  title: "Art of Keeping Home Minimal & Organised.",
  description: "Maecenas leo ante, gravida vel lectus ac, iaculis lobortis enim. Proin nec orci nec nisl consequat molestie quis sit amet elit. Ut venenatis dignissim diam in auctor.",
  thumbnail: "/assets/images/products/blog-1.png",
  shop: {
    id: "b5678cf6-28a2-4327-ac7c-53a48b583971",
    slug: "scroll-through",
    user: {
      id: "ec2e2713-bd92-451e-b792-cde1318a8e7f",
      email: "Lincoln19@gmail.com",
      phone: "577-286-7320",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1145.jpg",
      password: "_vJGJD5guoBUsvI",
      dateOfBirth: "1951-08-12T00:39:19.560Z",
      verified: true,
      name: {
        firstName: "Wilson",
        lastName: "Wisoky"
      }
    },
    email: "Freeman.Bailey94@hotmail.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  user: {
    id: "4f167465-df5e-4a0b-b12f-dd9889e99878",
    email: "King.Bernhard69@gmail.com",
    phone: "(837) 750-8829",
    avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/295.jpg",
    password: "ecqAhz55kZAhmgZ",
    dateOfBirth: "1970-11-28T13:46:48.135Z",
    verified: true,
    name: {
      firstName: "London",
      lastName: "Herzog"
    }
  },
  createdAt: "24 Feb 2020",
  slug: "art-of-keeping-home-minimal-and-organised.",
  comments: 3
}, {
  id: "c0533881-5c5c-4041-8e40-3d74cd4d1f97",
  title: "The Monthly Guide to Cleaning Your Home",
  description: "Maecenas leo ante, gravida vel lectus ac, iaculis lobortis enim. Proin nec orci nec nisl consequat molestie quis sit amet elit. Ut venenatis dignissim diam in auctor.",
  thumbnail: "/assets/images/products/blog-2.png",
  shop: {
    id: "a1649fd1-f9ba-4065-b2b4-f5a0170757de",
    slug: "scarlett-beauty",
    user: {
      id: "76c6c5dc-4d80-48dc-82b6-6f4bdba35d8f",
      email: "Jayde.Gutmann34@gmail.com",
      phone: "760-952-4971 x587",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/799.jpg",
      password: "yJ04pLFpLkAnWSg",
      dateOfBirth: "1960-09-18T19:07:42.220Z",
      verified: true,
      name: {
        firstName: "Vita",
        lastName: "Kerluke"
      }
    },
    email: "America.Zemlak@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  user: {
    id: "4f167465-df5e-4a0b-b12f-dd9889e99878",
    email: "King.Bernhard69@gmail.com",
    phone: "(837) 750-8829",
    avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/295.jpg",
    password: "ecqAhz55kZAhmgZ",
    dateOfBirth: "1970-11-28T13:46:48.135Z",
    verified: true,
    name: {
      firstName: "London",
      lastName: "Herzog"
    }
  },
  createdAt: "12 Jan 2020",
  slug: "the-monthly-guide-to-cleaning-your-home",
  comments: 14
}]; 
// products

export const products = [
// top-picks-products
{
  id: "c9b4f708-c060-485a-8d15-fdca75bb6ee9",
  slug: "bluetooth-speaker",
  shop: {
    id: "d6f04cce-29c2-413b-b6e4-ac8042fa4e50",
    slug: "constant-shoppers",
    user: {
      id: "4e5c0d99-b309-4d91-8b9b-0bc34ca8c6c3",
      email: "Kyleigh62@hotmail.com",
      phone: "1-291-347-7098 x165",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/372.jpg",
      password: "8EBudqpa5tYIzYh",
      dateOfBirth: "1966-11-20T17:18:34.603Z",
      verified: true,
      name: {
        firstName: "Sydnee",
        lastName: "Willms"
      }
    },
    email: "Euna87@hotmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Bluetooth Speaker",
  brand: null,
  price: 1262,
  size: null,
  colors: [],
  discount: 19,
  thumbnail: "/assets/images/products/bluetooth-headphone.png",
  images: ["/assets/images/products/bluetooth-headphone.png", "/assets/images/products/bluetooth-headphone.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 3,
  for: {
    demo: "gadget",
    type: "top-picks-products"
  }
}, {
  id: "e8c4f7ee-01f2-4797-a694-dc9e49cf5d2b",
  slug: "titan-watch",
  shop: {
    id: "5654b447-ed12-4ae8-a611-85b5fd3cb3bc",
    slug: "scarlett-beauty",
    user: {
      id: "1a8fdd3d-7d71-4c6b-ad8e-66589468fede",
      email: "Alanis_Waelchi@hotmail.com",
      phone: "997.532.0238",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/360.jpg",
      password: "7iKH8qCYpRuE1pn",
      dateOfBirth: "2001-08-02T18:50:25.541Z",
      verified: true,
      name: {
        firstName: "Skyla",
        lastName: "McGlynn"
      }
    },
    email: "Annette33@gmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Titan Watch",
  brand: null,
  price: 1093,
  size: null,
  colors: [],
  discount: 16,
  thumbnail: "/assets/images/products/watch-2.png",
  images: ["/assets/images/products/watch-2.png", "/assets/images/products/watch-2.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 2,
  for: {
    demo: "gadget",
    type: "top-picks-products"
  }
}, {
  id: "19daa126-04c5-41e5-94d0-5bb46f855eec",
  slug: "n95-white-mask",
  shop: {
    id: "e1107e4b-6455-4931-bd5f-554366125f35",
    slug: "scroll-through",
    user: {
      id: "cdee0739-ef36-438e-b805-7dfd91572312",
      email: "Modesto_Leffler6@gmail.com",
      phone: "1-988-230-0969 x0596",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1018.jpg",
      password: "LVmV0migO4QhhXY",
      dateOfBirth: "1943-09-28T00:21:30.497Z",
      verified: true,
      name: {
        firstName: "Bertha",
        lastName: "Streich"
      }
    },
    email: "Reymundo.Thiel@hotmail.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "N95 White Mask",
  brand: null,
  price: 1033,
  size: null,
  colors: [],
  discount: 19,
  thumbnail: "/assets/images/products/mask.png",
  images: ["/assets/images/products/mask.png", "/assets/images/products/mask.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5,
  for: {
    demo: "gadget",
    type: "top-picks-products"
  }
}, {
  id: "9e6b28f0-778e-49d9-b85a-5338311e9128",
  slug: "formal-shoe",
  shop: {
    id: "e1107e4b-6455-4931-bd5f-554366125f35",
    slug: "scroll-through",
    user: {
      id: "cdee0739-ef36-438e-b805-7dfd91572312",
      email: "Modesto_Leffler6@gmail.com",
      phone: "1-988-230-0969 x0596",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1018.jpg",
      password: "LVmV0migO4QhhXY",
      dateOfBirth: "1943-09-28T00:21:30.497Z",
      verified: true,
      name: {
        firstName: "Bertha",
        lastName: "Streich"
      }
    },
    email: "Reymundo.Thiel@hotmail.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Formal Shoe",
  brand: null,
  price: 1495,
  size: null,
  colors: [],
  discount: 17,
  thumbnail: "/assets/images/products/shoe.png",
  images: ["/assets/images/products/shoe.png", "/assets/images/products/shoe.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 3,
  for: {
    demo: "gadget",
    type: "top-picks-products"
  }
}, {
  id: "d28aad52-9848-46cf-b160-9b0e3234f872",
  slug: "bluetooth-speaker",
  shop: {
    id: "14421e49-81eb-403c-8f5f-6dcf3dc1d4a4",
    slug: "scarlett-beauty",
    user: {
      id: "3b2a7656-ee80-4211-b65d-0b908c55f914",
      email: "Troy.Schumm@hotmail.com",
      phone: "590.264.6208",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/982.jpg",
      password: "x1bT02ckGwGax03",
      dateOfBirth: "1988-05-13T15:27:57.842Z",
      verified: true,
      name: {
        firstName: "Gerald",
        lastName: "Homenick"
      }
    },
    email: "Robbie73@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Bluetooth Speaker",
  brand: null,
  price: 1321,
  size: null,
  colors: [],
  discount: 15,
  thumbnail: "/assets/images/products/watch-2.png",
  images: ["/assets/images/products/watch-2.png", "/assets/images/products/watch-2.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "gadget",
    type: "top-picks-products"
  }
}, {
  id: "2347185a-c04a-4359-9dac-7d28425ddbb4",
  slug: "bluetooth-speaker",
  shop: {
    id: "d6f04cce-29c2-413b-b6e4-ac8042fa4e50",
    slug: "constant-shoppers",
    user: {
      id: "4e5c0d99-b309-4d91-8b9b-0bc34ca8c6c3",
      email: "Kyleigh62@hotmail.com",
      phone: "1-291-347-7098 x165",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/372.jpg",
      password: "8EBudqpa5tYIzYh",
      dateOfBirth: "1966-11-20T17:18:34.603Z",
      verified: true,
      name: {
        firstName: "Sydnee",
        lastName: "Willms"
      }
    },
    email: "Euna87@hotmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Bluetooth Speaker",
  brand: null,
  price: 1278,
  size: null,
  colors: [],
  discount: 20,
  thumbnail: "/assets/images/products/bluetooth-headphone.png",
  images: ["/assets/images/products/bluetooth-headphone.png", "/assets/images/products/bluetooth-headphone.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "gadget",
    type: "top-picks-products"
  }
}, 
// most-viewed-products
{
  id: "82e8d147-9466-48dd-8c57-66b25050291d",
  slug: "asus-rog-strix-g15",
  shop: {
    id: "6329570f-be2a-4e03-8cf8-d0f8d489543d",
    slug: "scarlett-beauty",
    user: {
      id: "4811396b-a846-4672-951a-c88a4e2222d9",
      email: "Tommie18@gmail.com",
      phone: "(806) 528-5556 x02234",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/581.jpg",
      password: "2nx2WtZDbA47r6F",
      dateOfBirth: "2002-06-11T06:47:47.529Z",
      verified: true,
      name: {
        firstName: "Hyman",
        lastName: "Blick"
      }
    },
    email: "Ernest87@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "ASUS ROG Strix G15",
  brand: null,
  price: 250,
  size: null,
  colors: [],
  discount: 25,
  thumbnail: "/assets/images/products/chair-and-clothes.png",
  images: ["/assets/images/products/chair-and-clothes.png", "/assets/images/products/chair-and-clothes.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "gadget",
    type: "most-viewed-products"
  }
}, {
  id: "a77a3034-0b06-464c-9d65-b5ced8e238d8",
  slug: "asus-rog-strix-g15",
  shop: {
    id: "51762bdb-2bd0-4a69-ab83-524037a54896",
    slug: "keyboard-kiosk",
    user: {
      id: "7169a8b2-fd5a-4077-89d4-654dc97f4861",
      email: "Kay45@yahoo.com",
      phone: "(553) 561-7240 x512",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/453.jpg",
      password: "eiL34xf17edRlNU",
      dateOfBirth: "1962-01-21T04:13:22.338Z",
      verified: true,
      name: {
        firstName: "Vincenzo",
        lastName: "Nader"
      }
    },
    email: "Stephan_Hodkiewicz53@hotmail.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "ASUS ROG Strix G15",
  brand: null,
  price: 350,
  size: null,
  colors: [],
  discount: 15,
  thumbnail: "/assets/images/products/t-shirt3.png",
  images: ["/assets/images/products/t-shirt3.png", "/assets/images/products/t-shirt3.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 3,
  for: {
    demo: "gadget",
    type: "most-viewed-products"
  }
}, {
  id: "2dde2b0f-1db6-43f2-b53e-9cfc565b79f0",
  slug: "asus-rog-strix-g15",
  shop: {
    id: "eddc4d97-e9e2-462f-bde7-202d93cc8c3e",
    slug: "cybershop",
    user: {
      id: "2b28a970-8f72-4fc1-870e-87aa6f64fd2a",
      email: "Justus.Hermann57@hotmail.com",
      phone: "1-217-931-6843 x26246",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/568.jpg",
      password: "Em8NStYDSXPDVtF",
      dateOfBirth: "1982-10-05T19:34:27.645Z",
      verified: true,
      name: {
        firstName: "Blaze",
        lastName: "Rohan"
      }
    },
    email: "Lonie.Kuphal@gmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "ASUS ROG Strix G15",
  brand: null,
  price: 150,
  size: null,
  colors: [],
  discount: 28,
  thumbnail: "/assets/images/products/t-shirt4.png",
  images: ["/assets/images/products/t-shirt4.png", "/assets/images/products/t-shirt4.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5,
  for: {
    demo: "gadget",
    type: "most-viewed-products"
  }
}, {
  id: "a99a639c-3b48-467f-abed-8fb361a268e1",
  slug: "asus-rog-strix-g15",
  shop: {
    id: "6329570f-be2a-4e03-8cf8-d0f8d489543d",
    slug: "scarlett-beauty",
    user: {
      id: "4811396b-a846-4672-951a-c88a4e2222d9",
      email: "Tommie18@gmail.com",
      phone: "(806) 528-5556 x02234",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/581.jpg",
      password: "2nx2WtZDbA47r6F",
      dateOfBirth: "2002-06-11T06:47:47.529Z",
      verified: true,
      name: {
        firstName: "Hyman",
        lastName: "Blick"
      }
    },
    email: "Ernest87@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "ASUS ROG Strix G15",
  brand: null,
  price: 180,
  size: null,
  colors: [],
  discount: 21,
  thumbnail: "/assets/images/products/t-shirt5.png",
  images: ["/assets/images/products/t-shirt5.png", "/assets/images/products/t-shirt5.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "gadget",
    type: "most-viewed-products"
  }
}, {
  id: "3724eb46-2c61-44c0-9a07-977067fa2e2f",
  slug: "asus-rog-strix-g15",
  shop: {
    id: "be293af3-5b9c-463a-9b74-43cbb3fa43f0",
    slug: "coveted-clicks",
    user: {
      id: "25943a45-6a87-4a36-bf8d-bc3093b57ea5",
      email: "Aron.Collins@yahoo.com",
      phone: "(499) 893-2827 x101",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/72.jpg",
      password: "UHbU2hwyEEiy4kV",
      dateOfBirth: "1987-12-20T20:28:58.369Z",
      verified: true,
      name: {
        firstName: "Emelia",
        lastName: "Stanton"
      }
    },
    email: "Jerald_Labadie62@yahoo.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "ASUS ROG Strix G15",
  brand: null,
  price: 250,
  size: null,
  colors: [],
  discount: 25,
  thumbnail: "/assets/images/products/chair-and-clothes.png",
  images: ["/assets/images/products/chair-and-clothes.png", "/assets/images/products/chair-and-clothes.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "gadget",
    type: "most-viewed-products"
  }
}, {
  id: "8e123387-6f94-4114-86b8-f4f891956c05",
  slug: "asus-rog-strix-g15",
  shop: {
    id: "bc18875f-7f98-4b4b-9d9d-32d74c610992",
    slug: "scarlett-beauty",
    user: {
      id: "8fe40b5a-8d53-4274-b111-364facc15959",
      email: "Ed.Hoppe@yahoo.com",
      phone: "649-677-1836 x61861",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/472.jpg",
      password: "emO5AnimQyGJrXv",
      dateOfBirth: "1948-01-09T14:55:29.505Z",
      verified: true,
      name: {
        firstName: "Vincenzo",
        lastName: "Gottlieb"
      }
    },
    email: "Dwight99@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "ASUS ROG Strix G15",
  brand: null,
  price: 180,
  size: null,
  colors: [],
  discount: 21,
  thumbnail: "/assets/images/products/t-shirt4.png",
  images: ["/assets/images/products/t-shirt4.png", "/assets/images/products/t-shirt4.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "gadget",
    type: "most-viewed-products"
  }
}, {
  id: "a1fa1338-b8ed-4b0f-b8c6-798759ec6497",
  slug: "asus-rog-strix-g15",
  shop: {
    id: "bc18875f-7f98-4b4b-9d9d-32d74c610992",
    slug: "scarlett-beauty",
    user: {
      id: "8fe40b5a-8d53-4274-b111-364facc15959",
      email: "Ed.Hoppe@yahoo.com",
      phone: "649-677-1836 x61861",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/472.jpg",
      password: "emO5AnimQyGJrXv",
      dateOfBirth: "1948-01-09T14:55:29.505Z",
      verified: true,
      name: {
        firstName: "Vincenzo",
        lastName: "Gottlieb"
      }
    },
    email: "Dwight99@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "ASUS ROG Strix G15",
  brand: null,
  price: 150,
  size: null,
  colors: [],
  discount: 28,
  thumbnail: "/assets/images/products/t-shirt3.png",
  images: ["/assets/images/products/t-shirt3.png", "/assets/images/products/t-shirt3.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5,
  for: {
    demo: "gadget",
    type: "most-viewed-products"
  }
}, {
  id: "f3af4572-eb5b-4fe3-b464-18a4f579daba",
  slug: "asus-rog-strix-g15",
  shop: {
    id: "51762bdb-2bd0-4a69-ab83-524037a54896",
    slug: "keyboard-kiosk",
    user: {
      id: "7169a8b2-fd5a-4077-89d4-654dc97f4861",
      email: "Kay45@yahoo.com",
      phone: "(553) 561-7240 x512",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/453.jpg",
      password: "eiL34xf17edRlNU",
      dateOfBirth: "1962-01-21T04:13:22.338Z",
      verified: true,
      name: {
        firstName: "Vincenzo",
        lastName: "Nader"
      }
    },
    email: "Stephan_Hodkiewicz53@hotmail.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "ASUS ROG Strix G15",
  brand: null,
  price: 350,
  size: null,
  colors: [],
  discount: 15,
  thumbnail: "/assets/images/products/t-shirt5.png",
  images: ["/assets/images/products/t-shirt5.png", "/assets/images/products/t-shirt5.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 3,
  for: {
    demo: "gadget",
    type: "most-viewed-products"
  }
}, {
  id: "8f742f52-d149-498a-84c9-727683e3947b",
  slug: "asus-rog-strix-g15",
  shop: {
    id: "9cb4894e-262d-4919-81c5-bdf387154561",
    slug: "scroll-through",
    user: {
      id: "091dbc1c-0361-495b-8467-c654d66e87b5",
      email: "Darrick_Kemmer@yahoo.com",
      phone: "(576) 748-7240 x035",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/707.jpg",
      password: "M_vOgrrIowpoM23",
      dateOfBirth: "1948-10-15T04:02:53.036Z",
      verified: true,
      name: {
        firstName: "Robbie",
        lastName: "Fadel"
      }
    },
    email: "Breanna.Jakubowski@gmail.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "ASUS ROG Strix G15",
  brand: null,
  price: 299,
  size: null,
  colors: [],
  discount: 19,
  thumbnail: "/assets/images/products/chair-and-clothes.png",
  images: ["/assets/images/products/chair-and-clothes.png", "/assets/images/products/chair-and-clothes.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5,
  for: {
    demo: "gadget",
    type: "most-viewed-products"
  }
}, {
  id: "c851e848-b478-47ed-975a-ff1e674df168",
  slug: "asus-rog-strix-g15",
  shop: {
    id: "eddc4d97-e9e2-462f-bde7-202d93cc8c3e",
    slug: "cybershop",
    user: {
      id: "2b28a970-8f72-4fc1-870e-87aa6f64fd2a",
      email: "Justus.Hermann57@hotmail.com",
      phone: "1-217-931-6843 x26246",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/568.jpg",
      password: "Em8NStYDSXPDVtF",
      dateOfBirth: "1982-10-05T19:34:27.645Z",
      verified: true,
      name: {
        firstName: "Blaze",
        lastName: "Rohan"
      }
    },
    email: "Lonie.Kuphal@gmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "ASUS ROG Strix G15",
  brand: null,
  price: 180,
  size: null,
  colors: [],
  discount: 21,
  thumbnail: "/assets/images/products/t-shirt3.png",
  images: ["/assets/images/products/t-shirt3.png", "/assets/images/products/t-shirt3.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "gadget",
    type: "most-viewed-products"
  }
}, 
// new-arrival-products
{
  id: "822dd28a-2d42-4358-97e2-e458e90e244a",
  slug: "sunglass",
  shop: {
    id: "09bf037f-d36d-418b-af8c-694c2aa0306e",
    slug: "scarlett-beauty",
    user: {
      id: "31446590-a16e-43db-bda7-f99a1fa89826",
      email: "Jeff.Durgan59@hotmail.com",
      phone: "1-458-471-5506",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/256.jpg",
      password: "FMA38G_fqM31C_m",
      dateOfBirth: "1974-08-12T01:01:00.980Z",
      verified: true,
      name: {
        firstName: "Kaleb",
        lastName: "Ratke"
      }
    },
    email: "Richmond.Cummerata@gmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Sunglass",
  brand: null,
  price: 150,
  size: null,
  colors: [],
  discount: 8,
  thumbnail: "/assets/images/products/imagegoggles.png",
  images: ["/assets/images/products/imagegoggles.png", "/assets/images/products/imagegoggles.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5,
  for: {
    demo: "gadget",
    type: "new-arrival-products"
  }
}, {
  id: "42bcfdc9-2d09-4799-b088-51f24c16db97",
  slug: "makeup",
  shop: {
    id: "b949a892-c17b-493c-8bdb-100aee871397",
    slug: "scarlett-beauty",
    user: {
      id: "2b5234f6-97c0-4a41-b882-9adf8cd19235",
      email: "Merle53@yahoo.com",
      phone: "245.576.8310 x803",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1169.jpg",
      password: "MMS_Tm0leFCEcvL",
      dateOfBirth: "1960-04-27T06:24:52.311Z",
      verified: true,
      name: {
        firstName: "Zachary",
        lastName: "Jerde"
      }
    },
    email: "Princess.Schaden@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Makeup",
  brand: null,
  price: 250,
  size: null,
  colors: [],
  discount: 8,
  thumbnail: "/assets/images/products/lipstick (2).png",
  images: ["/assets/images/products/lipstick (2).png", "/assets/images/products/lipstick (2).png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "gadget",
    type: "new-arrival-products"
  }
}];