
// top navigation
export const categoryNavigation = [{
  category: "Top Categories",
  categoryItem: [{
    icon: "Home",
    title: "Home",
    href: "/products/search/Dariry & Eggs"
  }, {
    icon: "Popular",
    title: "Popular Products",
    href: "/products/search/Breakfast"
  }, {
    icon: "Trending",
    title: "Trending Products",
    href: "/products/search/Frozen"
  }, {
    icon: "Products",
    title: "All Products",
    href: "/products/search/vegetables"
  }]
}, {
  category: "Top Categories",
  categoryItem: [{
    icon: "Chair",
    title: "Chair",
    href: "/products/search/vegetables",
    child: [{
      title: "Pears, apples, quinces",
      href: "/products/search/Pears, apples, quinces"
    }, {
      title: "Peaches, plums, apricots",
      href: "/products/search/Peaches, plums, apricots"
    }, {
      title: "Grapes",
      href: "/products/search/Grapes"
    }]
  }, {
    icon: "Decoration",
    title: "Decors",
    href: "/products/search/Fruits & Vegetables",
    child: [{
      title: "Onion",
      href: "/products/search/Onion"
    }, {
      title: "Potato",
      href: "/products/search/Potato"
    }, {
      title: "Vegetable Pack",
      href: "/products/search/Vegetable Pack"
    }]
  }, {
    icon: "Interior",
    title: "Interior",
    href: "/products/search/Dariry & Eggs",
    child: [{
      title: "Pears, apples, quinces",
      href: "/products/search/Pears, apples, quinces"
    }, {
      title: "Peaches, plums, apricots",
      href: "/products/search/Peaches, plums, apricots"
    }, {
      title: "Grapes",
      href: "/products/search/Grapes"
    }]
  }, {
    icon: "Furniture",
    title: "Furniture",
    href: "/products/search/Dariry & Eggs",
    child: [{
      title: "Pears, apples, quinces",
      href: "/products/search/Pears, apples, quinces"
    }, {
      title: "Peaches, plums, apricots",
      href: "/products/search/Peaches, plums, apricots"
    }, {
      title: "Grapes",
      href: "/products/search/Grapes"
    }]
  }, {
    icon: "Sofa",
    title: "Sofa",
    href: "/products/search/Breakfast",
    child: [{
      title: "Pears, apples, quinces",
      href: "/products/search/Pears, apples, quinces"
    }, {
      title: "Peaches, plums, apricots",
      href: "/products/search/Peaches, plums, apricots"
    }, {
      title: "Grapes",
      href: "/products/search/Grapes"
    }]
  }, {
    icon: "Stool",
    title: "Stool",
    href: "/products/search/Frozen",
    child: [{
      title: "Pears, apples, quinces",
      href: "/products/search/Pears, apples, quinces"
    }, {
      title: "Peaches, plums, apricots",
      href: "/products/search/Peaches, plums, apricots"
    }, {
      title: "Grapes",
      href: "/products/search/Grapes"
    }]
  }, {
    icon: "Wardrobe",
    title: "Wardrobe",
    href: "/products/search/Organic",
    child: [{
      title: "Pears, apples, quinces",
      href: "/products/search/Pears, apples, quinces"
    }, {
      title: "Peaches, plums, apricots",
      href: "/products/search/Peaches, plums, apricots"
    }, {
      title: "Grapes",
      href: "/products/search/Grapes"
    }]
  }, {
    icon: "Dining",
    title: "Dining",
    href: "/products/search/Canned Food",
    child: [{
      title: "Pears, apples, quinces",
      href: "/products/search/Pears, apples, quinces"
    }, {
      title: "Peaches, plums, apricots",
      href: "/products/search/Peaches, plums, apricots"
    }, {
      title: "Grapes",
      href: "/products/search/Grapes"
    }]
  }, {
    icon: "Living",
    title: "Living",
    href: "/products/search/Coffee & Snacks"
  }, {
    icon: "RoundTable",
    title: "Coffee Tea Table",
    href: "/products/search/Coffee & Snacks"
  }, {
    icon: "RoomSet",
    title: "Living Room Sets",
    href: "/products/search/Coffee & Snacks"
  }]
}]; 
// carousel data

export const mainCarouselData = [{
  id: 1,
  title: "Modern Furniture.",
  subTitle: "A Beautiful House_",
  imgUrl: "/assets/images/headers/furniture-1.jpg",
  description: `How employees, surely the a said drops. Bathroom expected that systems let place. Her safely been little. Enterprises flows films it a fly the of wasn't designer the her thought. Enterprises flows films it a fly the of wasn't designer.`,
  buttonText: "Shop Now",
  buttonLink: "#"
}, {
  id: 2,
  title: "Modern Furniture.",
  subTitle: "A Beautiful House_",
  imgUrl: "/assets/images/headers/furniture-1.jpg",
  description: `How employees, surely the a said drops. Bathroom expected that systems let place. Her safely been little. Enterprises flows films it a fly the of wasn't designer the her thought. Enterprises flows films it a fly the of wasn't designer.`,
  buttonText: "Shop Now",
  buttonLink: "#"
}]; 
// products

export const products = [{
  id: "8b9f5a78-0dbb-4dd3-a718-aa7342b7691a",
  slug: "grey-sofa",
  shop: {
    id: "584323a3-059a-4990-8498-aa4fac9f22a7",
    slug: "constant-shoppers",
    user: {
      id: "08959f95-8448-42a1-b71b-9903504aad9b",
      email: "Elmo_Shields20@yahoo.com",
      phone: "(779) 888-4964",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/549.jpg",
      password: "n1yvwoxzoG7t_Lc",
      dateOfBirth: "1967-12-07T08:26:11.321Z",
      verified: true,
      name: {
        firstName: "Margot",
        lastName: "Batz"
      }
    },
    email: "Larry.Roberts13@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Grey Sofa",
  brand: null,
  price: 200,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 5,
  thumbnail: "/assets/images/furniture-products/furniture-22.png",
  images: ["/assets/images/furniture-products/furniture-22.png", "/assets/images/furniture-products/furniture-22.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "funiture",
    type: "top-new-product"
  }
}, {
  id: "dccff80a-1ee9-451e-9640-f7c9dad29a19",
  slug: "black-sofa",
  shop: {
    id: "f9b288e3-fc69-434e-8c63-0c6ccdbc908e",
    slug: "scroll-through",
    user: {
      id: "08959f95-8448-42a1-b71b-9903504aad9b",
      email: "Elmo_Shields20@yahoo.com",
      phone: "(779) 888-4964",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/549.jpg",
      password: "n1yvwoxzoG7t_Lc",
      dateOfBirth: "1967-12-07T08:26:11.321Z",
      verified: true,
      name: {
        firstName: "Margot",
        lastName: "Batz"
      }
    },
    email: "Rebekah.Fay@hotmail.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Black Sofa",
  brand: null,
  price: 125,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 0,
  thumbnail: "/assets/images/furniture-products/furniture-24.png",
  images: ["/assets/images/furniture-products/furniture-24.png", "/assets/images/furniture-products/furniture-24.png"],
  categories: [],
  status: "New",
  reviews: [],
  rating: 3,
  for: {
    demo: "funiture",
    type: "top-new-product"
  }
}, {
  id: "cb078492-e438-46d4-8f48-9a7db4a1e6db",
  slug: "comfortable-sofa",
  shop: {
    id: "9cc0b7cf-bd1c-4389-930d-c73d6a9debc9",
    slug: "scarlett-beauty",
    user: {
      id: "4bf18555-3e4e-418b-a63c-a096dd338d2d",
      email: "Cory.Kertzmann51@yahoo.com",
      phone: "(610) 685-9331 x5392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1039.jpg",
      password: "siDT7cyVz8hXjtg",
      dateOfBirth: "1996-10-11T01:30:35.680Z",
      verified: true,
      name: {
        firstName: "Anjali",
        lastName: "Hahn"
      }
    },
    email: "Juston74@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Comfortable Sofa",
  brand: null,
  price: 150,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 20,
  thumbnail: "/assets/images/furniture-products/furniture-27.png",
  images: ["/assets/images/furniture-products/furniture-27.png", "/assets/images/furniture-products/furniture-27.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 3,
  for: {
    demo: "funiture",
    type: "top-new-product"
  }
}, {
  id: "171954cb-787b-4e99-a53a-15a1320f8f55",
  slug: "study-chair",
  shop: {
    id: "f9b288e3-fc69-434e-8c63-0c6ccdbc908e",
    slug: "scroll-through",
    user: {
      id: "08959f95-8448-42a1-b71b-9903504aad9b",
      email: "Elmo_Shields20@yahoo.com",
      phone: "(779) 888-4964",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/549.jpg",
      password: "n1yvwoxzoG7t_Lc",
      dateOfBirth: "1967-12-07T08:26:11.321Z",
      verified: true,
      name: {
        firstName: "Margot",
        lastName: "Batz"
      }
    },
    email: "Rebekah.Fay@hotmail.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Study Chair",
  brand: null,
  price: 146,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 5,
  thumbnail: "/assets/images/furniture-products/furniture-26.png",
  images: ["/assets/images/furniture-products/furniture-26.png", "/assets/images/furniture-products/furniture-26.png"],
  categories: [],
  status: "New",
  reviews: [],
  rating: 2,
  for: {
    demo: "funiture",
    type: "top-new-product"
  }
}, {
  id: "229b547c-615a-4fd4-934b-5e49ac9ce887",
  slug: "study-gold-chair",
  shop: {
    id: "f0ae8957-963d-48d8-b6ef-c1db3ad6ead5",
    slug: "cybershop",
    user: {
      id: "705333ce-ded2-4992-b064-a5e954e5db28",
      email: "Devyn.Price@hotmail.com",
      phone: "1-613-635-2891 x9249",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/843.jpg",
      password: "smRmizWHLeniAfg",
      dateOfBirth: "1975-07-30T02:30:16.914Z",
      verified: true,
      name: {
        firstName: "Ned",
        lastName: "Veum"
      }
    },
    email: "Liana_Schulist@yahoo.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Study Gold Chair",
  brand: null,
  price: 138,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 0,
  thumbnail: "/assets/images/furniture-products/furniture-23.png",
  images: ["/assets/images/furniture-products/furniture-23.png", "/assets/images/furniture-products/furniture-23.png"],
  categories: [],
  status: "New",
  reviews: [],
  rating: 5,
  for: {
    demo: "funiture",
    type: "top-new-product"
  }
}, {
  id: "977390c9-c324-4a04-bce1-ccb13a50ea06",
  slug: "double-sofa",
  shop: {
    id: "d6045757-681b-4e7a-bda8-3d92ae2fc4a2",
    slug: "anytime-buys",
    user: {
      id: "16f3d56f-0eb1-436e-880e-6863f46aaa9b",
      email: "Haleigh_Spinka@yahoo.com",
      phone: "236.313.1571 x644",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/499.jpg",
      password: "7c443hC0rzZaEGr",
      dateOfBirth: "1999-10-23T20:44:54.801Z",
      verified: true,
      name: {
        firstName: "Orin",
        lastName: "McGlynn"
      }
    },
    email: "Pauline35@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Double Sofa",
  brand: null,
  price: 100,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 13,
  thumbnail: "/assets/images/furniture-products/furniture-25.png",
  images: ["/assets/images/furniture-products/furniture-25.png", "/assets/images/furniture-products/furniture-25.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "funiture",
    type: "top-new-product"
  }
}, {
  id: "7fb38902-15cf-4903-bb2d-2b0468424be0",
  slug: "study-chair",
  shop: {
    id: "b77cfd3a-e2ca-43d4-aa16-853e63655e16",
    slug: "scarlett-beauty",
    user: {
      id: "0f12371c-eba4-4fdc-8263-c2ff9b6b0778",
      email: "Cathrine.Schmeler@hotmail.com",
      phone: "(939) 480-5277",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1043.jpg",
      password: "nm39svn3vDbJWrO",
      dateOfBirth: "1944-02-29T06:42:41.397Z",
      verified: true,
      name: {
        firstName: "Katlynn",
        lastName: "Barrows"
      }
    },
    email: "Jermain4@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Study Chair",
  brand: null,
  price: 146,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 5,
  thumbnail: "/assets/images/furniture-products/furniture-26.png",
  images: ["/assets/images/furniture-products/furniture-26.png", "/assets/images/furniture-products/furniture-26.png"],
  categories: [],
  status: "New",
  reviews: [],
  rating: 2,
  for: {
    demo: "funiture",
    type: "top-selling-product"
  }
}, {
  id: "be1be17a-920b-4cb8-b1c3-25c91bfdb144",
  slug: "study-gold-chair",
  shop: {
    id: "589e9f00-d70c-4b7b-8107-d2a2b5983ca1",
    slug: "constant-shoppers",
    user: {
      id: "a65e0d68-ff07-4aea-8590-24a07462d7ec",
      email: "Talia14@gmail.com",
      phone: "(917) 424-3973 x6406",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/358.jpg",
      password: "zig2jzJlLNlhjqM",
      dateOfBirth: "1986-03-22T11:52:05.138Z",
      verified: true,
      name: {
        firstName: "Cydney",
        lastName: "Hettinger"
      }
    },
    email: "Horace.Powlowski10@hotmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Study Gold Chair",
  brand: null,
  price: 138,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 0,
  thumbnail: "/assets/images/furniture-products/furniture-23.png",
  images: ["/assets/images/furniture-products/furniture-23.png", "/assets/images/furniture-products/furniture-23.png"],
  categories: [],
  status: "New",
  reviews: [],
  rating: 5,
  for: {
    demo: "funiture",
    type: "top-selling-product"
  }
}, {
  id: "9bdc1598-5884-4708-ac3f-90a0d8ffe14c",
  slug: "double-sofa",
  shop: {
    id: "589e9f00-d70c-4b7b-8107-d2a2b5983ca1",
    slug: "constant-shoppers",
    user: {
      id: "a65e0d68-ff07-4aea-8590-24a07462d7ec",
      email: "Talia14@gmail.com",
      phone: "(917) 424-3973 x6406",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/358.jpg",
      password: "zig2jzJlLNlhjqM",
      dateOfBirth: "1986-03-22T11:52:05.138Z",
      verified: true,
      name: {
        firstName: "Cydney",
        lastName: "Hettinger"
      }
    },
    email: "Horace.Powlowski10@hotmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Double Sofa",
  brand: null,
  price: 100,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 13,
  thumbnail: "/assets/images/furniture-products/furniture-24.png",
  images: ["/assets/images/furniture-products/furniture-24.png", "/assets/images/furniture-products/furniture-24.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "funiture",
    type: "top-selling-product"
  }
}, {
  id: "22cb9a87-06bd-4607-9996-32c0839b18ee",
  slug: "brown-sofa",
  shop: {
    id: "55428863-c018-4415-9e31-d3395b7389a7",
    slug: "scroll-through",
    user: {
      id: "ecfbddb3-9750-4e33-9b5a-cb7ac94a5fa3",
      email: "Krystina.Walter@gmail.com",
      phone: "353.329.2944 x97364",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/869.jpg",
      password: "Hizfs0Y17bQWSn_",
      dateOfBirth: "1979-06-23T05:57:45.005Z",
      verified: true,
      name: {
        firstName: "Lawrence",
        lastName: "Stracke"
      }
    },
    email: "Dorthy.Schaefer@gmail.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Brown Sofa",
  brand: null,
  price: 457,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 0,
  thumbnail: "/assets/images/furniture-products/furniture-25.png",
  images: ["/assets/images/furniture-products/furniture-25.png", "/assets/images/furniture-products/furniture-25.png"],
  categories: [],
  status: "New",
  reviews: [],
  rating: 3,
  for: {
    demo: "funiture",
    type: "top-selling-product"
  }
}, {
  id: "7cba828c-0cfd-45f5-ba45-2727eef43c58",
  slug: "arm-sofa",
  shop: {
    id: "db223f85-cfea-4683-9718-a0f3dc5387ac",
    slug: "keyboard-kiosk",
    user: {
      id: "0f12371c-eba4-4fdc-8263-c2ff9b6b0778",
      email: "Cathrine.Schmeler@hotmail.com",
      phone: "(939) 480-5277",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1043.jpg",
      password: "nm39svn3vDbJWrO",
      dateOfBirth: "1944-02-29T06:42:41.397Z",
      verified: true,
      name: {
        firstName: "Katlynn",
        lastName: "Barrows"
      }
    },
    email: "Julio_Bauch@yahoo.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Arm Sofa",
  brand: null,
  price: 110,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 17,
  thumbnail: "/assets/images/furniture-products/furniture-21.png",
  images: ["/assets/images/furniture-products/furniture-21.png", "/assets/images/furniture-products/furniture-21.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5,
  for: {
    demo: "funiture",
    type: "top-selling-product"
  }
}, {
  id: "d23fa907-135b-4d7b-85bf-2e1b2ee8df95",
  slug: "couple-sofa",
  shop: {
    id: "8252bab9-7a91-4f48-8319-8a0be32f9697",
    slug: "word-wide-wishes",
    user: {
      id: "fe515fdf-1d1d-408d-9bb6-6d4a3cb50e99",
      email: "Rhea_Crist@gmail.com",
      phone: "387.482.5979 x53624",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/687.jpg",
      password: "1wuR8uo9B0SHaH6",
      dateOfBirth: "1979-12-05T01:20:44.112Z",
      verified: true,
      name: {
        firstName: "Frederik",
        lastName: "Glover"
      }
    },
    email: "Cathrine.Hilpert2@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Couple Sofa",
  brand: null,
  price: 146,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 2,
  thumbnail: "/assets/images/furniture-products/furniture-27.png",
  images: ["/assets/images/furniture-products/furniture-27.png", "/assets/images/furniture-products/furniture-27.png"],
  categories: [],
  status: "New",
  reviews: [],
  rating: 2,
  for: {
    demo: "funiture",
    type: "top-selling-product"
  }
}, {
  id: "7d81452b-ad09-4e3d-a86b-6927d08458c6",
  slug: "grey-sofa",
  shop: {
    id: "fce98308-142e-437b-8153-13dae9097e8c",
    slug: "scroll-through",
    user: {
      id: "3570c037-c40d-4e14-a72c-20669a5eaa93",
      email: "Jude14@gmail.com",
      phone: "988-532-6735 x4267",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/547.jpg",
      password: "TiWSsYtrzs2PgQj",
      dateOfBirth: "1992-09-11T09:25:44.098Z",
      verified: true,
      name: {
        firstName: "Titus",
        lastName: "Osinski"
      }
    },
    email: "Aileen.Mitchell@hotmail.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Grey Sofa",
  brand: null,
  price: 200,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 5,
  thumbnail: "/assets/images/furniture-products/furniture-22.png",
  images: ["/assets/images/furniture-products/furniture-22.png", "/assets/images/furniture-products/furniture-22.png", "/assets/images/furniture-products/furniture-22.png"],
  categories: ["furniture"],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "funiture",
    type: "all-product"
  }
}, {
  id: "38b5139b-f137-4b7f-b8e4-f527fbfead19",
  slug: "black-sofa",
  shop: {
    id: "a9fa749e-efe6-4469-b4e3-5c5bcd74792d",
    slug: "scarlett-beauty",
    user: {
      id: "8f3d8943-8b7a-4fdf-beef-504af9cb2209",
      email: "Ole_Witting@yahoo.com",
      phone: "562-448-5856 x01231",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/61.jpg",
      password: "WRp32o6zyKjrBPB",
      dateOfBirth: "1993-01-15T21:42:41.182Z",
      verified: true,
      name: {
        firstName: "Ethel",
        lastName: "Swaniawski"
      }
    },
    email: "Litzy_Upton79@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Black Sofa",
  brand: null,
  price: 125,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 0,
  thumbnail: "/assets/images/furniture-products/furniture-24.png",
  images: ["/assets/images/furniture-products/furniture-24.png", "/assets/images/furniture-products/furniture-24.png", "/assets/images/furniture-products/furniture-24.png"],
  categories: ["furniture"],
  status: "New",
  reviews: [],
  rating: 3,
  for: {
    demo: "funiture",
    type: "all-product"
  }
}, {
  id: "5f8dce61-120f-46d2-a439-77cb18327d21",
  slug: "comfortable-sofa",
  shop: {
    id: "ac44f41a-3b4a-463c-80ef-5d7df60460c1",
    slug: "anytime-buys",
    user: {
      id: "d5bb30eb-a033-452c-96e8-c14b3b63ec39",
      email: "Anais22@yahoo.com",
      phone: "1-470-840-5632 x1492",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1097.jpg",
      password: "RVGmPE8KCIAeNbo",
      dateOfBirth: "1985-11-06T18:36:51.348Z",
      verified: true,
      name: {
        firstName: "Tom",
        lastName: "Bogan"
      }
    },
    email: "Deborah_Mante64@gmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Comfortable Sofa",
  brand: null,
  price: 150,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 20,
  thumbnail: "/assets/images/furniture-products/furniture-23.png",
  images: ["/assets/images/furniture-products/furniture-23.png", "/assets/images/furniture-products/furniture-23.png", "/assets/images/furniture-products/furniture-23.png"],
  categories: ["furniture"],
  status: null,
  reviews: [],
  rating: 3,
  for: {
    demo: "funiture",
    type: "all-product"
  }
}, {
  id: "c1b537ee-3809-4f06-b100-3cfd775d7305",
  slug: "study-chair",
  shop: {
    id: "dd0f580b-75c6-4486-8e4d-22fd2ed20d91",
    slug: "word-wide-wishes",
    user: {
      id: "8f3d8943-8b7a-4fdf-beef-504af9cb2209",
      email: "Ole_Witting@yahoo.com",
      phone: "562-448-5856 x01231",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/61.jpg",
      password: "WRp32o6zyKjrBPB",
      dateOfBirth: "1993-01-15T21:42:41.182Z",
      verified: true,
      name: {
        firstName: "Ethel",
        lastName: "Swaniawski"
      }
    },
    email: "Quinten75@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Study Chair",
  brand: null,
  price: 146,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 5,
  thumbnail: "/assets/images/furniture-products/furniture-26.png",
  images: ["/assets/images/furniture-products/furniture-26.png", "/assets/images/furniture-products/furniture-26.png", "/assets/images/furniture-products/furniture-26.png"],
  categories: ["furniture"],
  status: "New",
  reviews: [],
  rating: 2,
  for: {
    demo: "funiture",
    type: "all-product"
  }
}, {
  id: "0a378c34-d7a0-4f5b-a588-8f1850db25f6",
  slug: "study-gold-chair",
  shop: {
    id: "d8ce537f-5ae7-4638-84f3-097583f9678d",
    slug: "constant-shoppers",
    user: {
      id: "15b2490e-6312-4551-b4b8-be009e91fd3f",
      email: "Wendell.Kling77@hotmail.com",
      phone: "257-773-0208",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/234.jpg",
      password: "YydeA132G25RfXb",
      dateOfBirth: "1954-04-19T22:03:55.219Z",
      verified: true,
      name: {
        firstName: "Maiya",
        lastName: "Cormier"
      }
    },
    email: "Gilbert_Jacobson24@gmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Study Gold Chair",
  brand: null,
  price: 138,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 0,
  thumbnail: "/assets/images/furniture-products/furniture-23.png",
  images: ["/assets/images/furniture-products/furniture-23.png", "/assets/images/furniture-products/furniture-23.png", "/assets/images/furniture-products/furniture-23.png"],
  categories: ["furniture"],
  status: "New",
  reviews: [],
  rating: 5,
  for: {
    demo: "funiture",
    type: "all-product"
  }
}, {
  id: "a9717bbd-fea8-4c82-819a-68f6c885e3be",
  slug: "double-sofa",
  shop: {
    id: "d8ce537f-5ae7-4638-84f3-097583f9678d",
    slug: "constant-shoppers",
    user: {
      id: "15b2490e-6312-4551-b4b8-be009e91fd3f",
      email: "Wendell.Kling77@hotmail.com",
      phone: "257-773-0208",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/234.jpg",
      password: "YydeA132G25RfXb",
      dateOfBirth: "1954-04-19T22:03:55.219Z",
      verified: true,
      name: {
        firstName: "Maiya",
        lastName: "Cormier"
      }
    },
    email: "Gilbert_Jacobson24@gmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Double Sofa",
  brand: null,
  price: 100,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 13,
  thumbnail: "/assets/images/furniture-products/furniture-25.png",
  images: ["/assets/images/furniture-products/furniture-25.png", "/assets/images/furniture-products/furniture-25.png", "/assets/images/furniture-products/furniture-25.png"],
  categories: ["furniture"],
  status: null,
  reviews: [],
  rating: 4,
  for: {
    demo: "funiture",
    type: "all-product"
  }
}, {
  id: "cdca4a89-ab77-4b86-9201-7a7af90b1dfe",
  slug: "brown-sofa",
  shop: {
    id: "e8c2715e-6bb0-41af-b668-6135a68d0981",
    slug: "keyboard-kiosk",
    user: {
      id: "3570c037-c40d-4e14-a72c-20669a5eaa93",
      email: "Jude14@gmail.com",
      phone: "988-532-6735 x4267",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/547.jpg",
      password: "TiWSsYtrzs2PgQj",
      dateOfBirth: "1992-09-11T09:25:44.098Z",
      verified: true,
      name: {
        firstName: "Titus",
        lastName: "Osinski"
      }
    },
    email: "Erich.Lowe@yahoo.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Brown Sofa",
  brand: null,
  price: 457,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 0,
  thumbnail: "/assets/images/furniture-products/furniture-25.png",
  images: ["/assets/images/furniture-products/furniture-25.png", "/assets/images/furniture-products/furniture-25.png", "/assets/images/furniture-products/furniture-25.png"],
  categories: ["furniture"],
  status: "New",
  reviews: [],
  rating: 3,
  for: {
    demo: "funiture",
    type: "all-product"
  }
}, {
  id: "842029c6-12b5-4bfc-b386-443739f58334",
  slug: "arm-sofa",
  shop: {
    id: "dd0f580b-75c6-4486-8e4d-22fd2ed20d91",
    slug: "word-wide-wishes",
    user: {
      id: "8f3d8943-8b7a-4fdf-beef-504af9cb2209",
      email: "Ole_Witting@yahoo.com",
      phone: "562-448-5856 x01231",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/61.jpg",
      password: "WRp32o6zyKjrBPB",
      dateOfBirth: "1993-01-15T21:42:41.182Z",
      verified: true,
      name: {
        firstName: "Ethel",
        lastName: "Swaniawski"
      }
    },
    email: "Quinten75@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Arm Sofa",
  brand: null,
  price: 110,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 17,
  thumbnail: "/assets/images/furniture-products/furniture-28.png",
  images: ["/assets/images/furniture-products/furniture-28.png", "/assets/images/furniture-products/furniture-28.png", "/assets/images/furniture-products/furniture-28.png"],
  categories: ["furniture"],
  status: null,
  reviews: [],
  rating: 5,
  for: {
    demo: "funiture",
    type: "all-product"
  }
}, {
  id: "6c9a8f14-d0a8-41eb-a8e4-a12e77f69cf3",
  slug: "couple-sofa",
  shop: {
    id: "ac44f41a-3b4a-463c-80ef-5d7df60460c1",
    slug: "anytime-buys",
    user: {
      id: "d5bb30eb-a033-452c-96e8-c14b3b63ec39",
      email: "Anais22@yahoo.com",
      phone: "1-470-840-5632 x1492",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1097.jpg",
      password: "RVGmPE8KCIAeNbo",
      dateOfBirth: "1985-11-06T18:36:51.348Z",
      verified: true,
      name: {
        firstName: "Tom",
        lastName: "Bogan"
      }
    },
    email: "Deborah_Mante64@gmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Couple Sofa",
  brand: null,
  price: 146,
  size: null,
  colors: ["#7D879C", "#4E97FD", "#33D067", "#BE7374"],
  discount: 2,
  thumbnail: "/assets/images/furniture-products/furniture-27.png",
  images: ["/assets/images/furniture-products/furniture-27.png", "/assets/images/furniture-products/furniture-27.png", "/assets/images/furniture-products/furniture-27.png"],
  categories: ["furniture"],
  status: "New",
  reviews: [],
  rating: 2,
  for: {
    demo: "funiture",
    type: "all-product"
  }
}];