
// stories
export const stories = [{
  id: 1,
  url: "#",
  image: "/assets/images/stories/story-1.jpg",
  imageBig: "/assets/images/stories/story-1-big.jpg"
}, {
  id: 2,
  url: "#",
  image: "/assets/images/stories/story-2.jpg",
  imageBig: "/assets/images/stories/story-2-big.jpg"
}, {
  id: 3,
  url: "#",
  image: "/assets/images/stories/story-3.jpg",
  imageBig: "/assets/images/stories/story-3-big.jpg"
}, {
  id: 4,
  url: "#",
  image: "/assets/images/stories/story-4.jpg",
  imageBig: "/assets/images/stories/story-4-big.jpg"
}, {
  id: 5,
  url: "#",
  image: "/assets/images/stories/story-5.jpg",
  imageBig: "/assets/images/stories/story-5-big.jpg"
}, {
  id: 6,
  url: "#",
  image: "/assets/images/stories/story-6.jpg",
  imageBig: "/assets/images/stories/story-6-big.jpg"
}, {
  id: 7,
  url: "#",
  image: "/assets/images/stories/story-1.jpg",
  imageBig: "/assets/images/stories/story-1-big.jpg"
}, {
  id: 8,
  url: "#",
  image: "/assets/images/stories/story-2.jpg",
  imageBig: "/assets/images/stories/story-2-big.jpg"
}]; 
// product list

export const products = [{
  id: "b66a92a2-56d0-4512-9db6-f24cb97139g1",
  slug: "banana",
  shop: {
    id: "50a7bd5c-8089-416f-b887-9921eebf290e",
    slug: "constant-shoppers",
    user: {
      id: "773532bc-4f41-46d9-94dd-4636c4adb0e4",
      email: "Makayla_Sipes@gmail.com",
      phone: "(329) 360-7080 x9996",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/251.jpg",
      password: "TVkxUX2gm0TXK6u",
      dateOfBirth: "1969-04-01T09:20:22.494Z",
      verified: true,
      name: {
        firstName: "Carter",
        lastName: "Leffler"
      }
    },
    email: "Ian_Monahan@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Banana",
  brand: null,
  price: 146,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/Groceries Shop/15.png",
  images: ["/assets/images/Groceries Shop/15.png", "/assets/images/Groceries Shop/15.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 2
}, {
  id: "b66a92a2-56d0-4512-9db6-f24cb97139g2",
  slug: "fresh-broccoli-crowns",
  shop: {
    id: "50a7bd5c-8089-416f-b887-9921eebf290e",
    slug: "constant-shoppers",
    user: {
      id: "773532bc-4f41-46d9-94dd-4636c4adb0e4",
      email: "Makayla_Sipes@gmail.com",
      phone: "(329) 360-7080 x9996",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/251.jpg",
      password: "TVkxUX2gm0TXK6u",
      dateOfBirth: "1969-04-01T09:20:22.494Z",
      verified: true,
      name: {
        firstName: "Carter",
        lastName: "Leffler"
      }
    },
    email: "Ian_Monahan@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Broccoli Crowns",
  brand: null,
  price: 146,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/Groceries Shop/1.png",
  images: ["/assets/images/Groceries Shop/1.png", "/assets/images/Groceries Shop/1.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 2
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696cg3",
  slug: "fresh-cauliflower",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Cauliflower",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/2.png",
  images: ["/assets/images/Groceries Shop/2.png", "/assets/images/Groceries Shop/2.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696cg4",
  slug: "fresh-green-cabbage",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Green Cabbage",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/3.png",
  images: ["/assets/images/Groceries Shop/3.png", "/assets/images/Groceries Shop/3.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696cg5",
  slug: "fresh-purple-onions",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Purple Onions",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/4.png",
  images: ["/assets/images/Groceries Shop/4.png", "/assets/images/Groceries Shop/4.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696cg6",
  slug: "fresh-yellow-onions",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Yellow Onions",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/5.png",
  images: ["/assets/images/Groceries Shop/5.png", "/assets/images/Groceries Shop/5.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696cg7",
  slug: "jumbo-red-onions",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Jumbo Red Onions",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/6.png",
  images: ["/assets/images/Groceries Shop/6.png", "/assets/images/Groceries Shop/6.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696cg8",
  slug: "garlic-bulb-fresh",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Garlic Bulb Fresh",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/7.png",
  images: ["/assets/images/Groceries Shop/7.png", "/assets/images/Groceries Shop/7.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696cg9",
  slug: "organic-green-beans ",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Organic Green Beans",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/8.png",
  images: ["/assets/images/Groceries Shop/8.png", "/assets/images/Groceries Shop/8.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696g10",
  slug: "fresh-lime",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Lime",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/9.png",
  images: ["/assets/images/Groceries Shop/9.png", "/assets/images/Groceries Shop/9.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696g11",
  slug: "fresh-pumpkin",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Pumpkin",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/10.png",
  images: ["/assets/images/Groceries Shop/10.png", "/assets/images/Groceries Shop/10.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696g12",
  slug: "fresh-purple-eggplant",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Purple Eggplant",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/11.png",
  images: ["/assets/images/Groceries Shop/11.png", "/assets/images/Groceries Shop/11.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696g13",
  slug: "fresh-carrots",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Carrots",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/12.png",
  images: ["/assets/images/Groceries Shop/12.png", "/assets/images/Groceries Shop/12.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696g14",
  slug: "fresh-red-bell-pepper",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Red Bell Pepper",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/13.png",
  images: ["/assets/images/Groceries Shop/13.png", "/assets/images/Groceries Shop/13.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696g15",
  slug: "fresh-serrano-pepper",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Serrano Pepper",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/14.png",
  images: ["/assets/images/Groceries Shop/14.png", "/assets/images/Groceries Shop/14.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}, {
  id: "7e10b5fc-90da-4a78-97f4-05f9a5696g16",
  slug: "fresh-yellow-banana",
  shop: {
    id: "791e3523-7489-4217-bcc0-d3377fc1945c",
    slug: "word-wide-wishes",
    user: {
      id: "9df11153-5fd2-4a97-a13f-82a60327517d",
      email: "Alva11@yahoo.com",
      phone: "(712) 354-0462",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/125.jpg",
      password: "YuZI5NTONPqgo4Z",
      dateOfBirth: "1949-05-05T07:40:11.142Z",
      verified: true,
      name: {
        firstName: "Everett",
        lastName: "Murray"
      }
    },
    email: "Erick_Bins@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh Yellow Banana",
  brand: null,
  price: 105,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/Groceries Shop/15.png",
  images: ["/assets/images/Groceries Shop/15.png", "/assets/images/Groceries Shop/15.png"],
  categories: [],
  status: null,
  reviews: [],
  rating: 5
}]; 
// categories

export const categories = [{
  name: "Grocery",
  icon: "Grocery",
  path: "#",
  children: [{
    name: "Fruits & Vegetables",
    path: "/grocery-4/categories/fruits-vegetables",
    children: [{
      name: "Fruits",
      path: "/grocery-4/categories/fruits"
    }, {
      name: "Vegetables",
      path: "/grocery-4/categories/vegetables"
    }]
  }, {
    name: "Meat & Fish",
    path: "#",
    children: [{
      name: "Meat",
      path: "/grocery-4/categories/meat"
    }, {
      name: "Fish",
      path: "/grocery-4/categories/fish"
    }]
  }, {
    name: "Cooking",
    path: "#",
    children: [{
      name: "Rice",
      path: "/grocery-4/categories/rice"
    }, {
      name: "Oil",
      path: "/grocery-4/categories/oil"
    }]
  }, {
    name: "Sauces & Pickles",
    path: "#",
    children: [{
      name: "Pickles",
      path: "/grocery-4/categories/pickles"
    }, {
      name: "Tomato Sauce",
      path: "/grocery-4/categories/tomato-sauce"
    }]
  }, {
    name: "Dairy & Eggs",
    path: "#",
    children: [{
      name: "Eggs",
      path: "/grocery-4/categories/eggs"
    }, {
      name: "Cheese",
      path: "/grocery-4/categories/cheese"
    }]
  }, {
    name: "Breakfast",
    path: "#",
    children: [{
      name: "Breads",
      path: "/grocery-4/categories/breads"
    }, {
      name: "Coffee",
      path: "/grocery-4/categories/coffee"
    }]
  }, {
    name: "Candy & Chocolate",
    path: "#",
    children: [{
      name: "Chocolates",
      path: "/grocery-4/categories/chocolates"
    }, {
      name: "Candies",
      path: "/grocery-4/categories/candies"
    }]
  }, {
    name: "Snacks",
    path: "#",
    children: [{
      name: "Cookies",
      path: "/grocery-4/categories/cookies"
    }, {
      name: "Noodles",
      path: "/grocery-4/categories/noodles"
    }]
  }, {
    name: "Beverages",
    path: "#",
    children: [{
      name: "Soft Drinks",
      path: "/grocery-4/categories/soft-drinks"
    }, {
      name: "Water",
      path: "/grocery-4/categories/water"
    }]
  }, {
    name: "Frozen & Canned",
    path: "#",
    children: [{
      name: "Chicken Snacks",
      path: "/grocery-4/categories/chicken-snacks"
    }, {
      name: "Beef Snacks",
      path: "/grocery-4/categories/beef-snacks"
    }]
  }, {
    name: "Diabetic Food",
    path: "/grocery-4/categories/diabetic-food"
  }]
}, {
  name: "Beauty & Makeup",
  icon: "Beauty",
  path: "#",
  children: [{
    name: "Hand Wash",
    path: "/grocery-4/categories/hand-wash"
  }, {
    name: "Face Wash",
    path: "/grocery-4/categories/face-wash"
  }, {
    name: "Tissue & Wipes",
    path: "/grocery-4/categories/tissue-wipes"
  }]
}, {
  name: "Electronics",
  icon: "Electronics",
  path: "#",
  children: [{
    name: "Lights",
    path: "/grocery-4/categories/lights"
  }, {
    name: "Mobiles",
    path: "/grocery-4/categories/mobiles"
  }]
}, {
  path: "#",
  name: "Furniture",
  icon: "Furniture",
  children: [{
    name: "Bed",
    path: "/grocery-4/categories/bed"
  }]
}, {
  path: "#",
  name: "Fashion",
  icon: "Fashion",
  children: [{
    name: "Men's Fashion",
    path: "/grocery-4/categories/men-fashion"
  }, {
    name: "Women's Fashion",
    path: "/grocery-4/categories/women-fashion"
  }]
}, {
  name: "Bakery",
  icon: "Bakery",
  path: "/grocery-4/categories/bakery"
}];